// constants.js

//dev
// export const CLOUDFRONT_URL = `https://master.d3tk9dm9sdjnvb.amplifyapp.com/`;
// export const COGNITO_DOMAIN_NAME = 'fluxus-ai-dev';
// export const APP_CLIENT_ID = '6956tbqjg3886p47oklvu9g9lt';
// export const APP_SECRET = '1ejt8kui4nc1cmpjcapneuj5kjankd33741kv55jq45tkjdn2tr';
// export const API_generateReport = 'https://3bmpr6l95f.execute-api.us-west-2.amazonaws.com/dev/generate-company-report'
// export const API_getprogress = 'https://3bmpr6l95f.execute-api.us-west-2.amazonaws.com/dev/get-progress?transactionID='
// export const API_gettable = 'https://3bmpr6l95f.execute-api.us-west-2.amazonaws.com/dev/get-table'

// prd
// export const CLOUDFRONT_URL = `https://www.panya.fyi/`;
// export const COGNITO_DOMAIN_NAME = 'obsolete-ai-prd';
// export const APP_CLIENT_ID = '5i4beg3hs4s9sam00iibhtsss';
// export const APP_SECRET = '1t9re9c5ucliso2cpkgg935ka7ps72crtec16lefa6ibo87hejfr';
// export const API_generateReport = 'https://w18ui082fc.execute-api.us-west-2.amazonaws.com/prd/generate-company-report'
// export const API_getprogress = 'https://w18ui082fc.execute-api.us-west-2.amazonaws.com/prd/get-progress-v3?transactionID='
// export const API_gettable = 'https://w18ui082fc.execute-api.us-west-2.amazonaws.com/prd/get-table'

// // prd
export const CLOUDFRONT_URL = `https://www.panya.fyi/`;
export const COGNITO_DOMAIN_NAME = 'fluxus';
export const APP_CLIENT_ID = '4m2kktmbebmedllleg6j0lt862';
export const APP_SECRET = 'etopkuq74dp89f03lqbrvc6dsgc9oa5qjdsulv142qiqbnqm6km';
export const API_generateReport = 'https://w18ui082fc.execute-api.us-west-2.amazonaws.com/prd/generate-company-report'
export const API_getprogress = 'https://w18ui082fc.execute-api.us-west-2.amazonaws.com/prd/get-progress-v3?transactionID='
export const API_gettable = 'https://w18ui082fc.execute-api.us-west-2.amazonaws.com/prd/get-table'

//dev
// export const CLOUDFRONT_URL = `https://www.panya.fyi/`;
// export const COGNITO_DOMAIN_NAME = 'fluxus';
// export const APP_CLIENT_ID = '4m2kktmbebmedllleg6j0lt862';
// export const APP_SECRET = 'etopkuq74dp89f03lqbrvc6dsgc9oa5qjdsulv142qiqbnqm6km';
// export const API_generateReport = 'https://loiy8dxvd6.execute-api.us-west-2.amazonaws.com/dev/generate-company-report'
// export const API_getprogress = 'https://loiy8dxvd6.execute-api.us-west-2.amazonaws.com/dev/get-progress?transactionID='
// export const API_gettable = 'https://loiy8dxvd6.execute-api.us-west-2.amazonaws.com/dev/get-table'