function isMobileDevice() {
  // Get the screen width
  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  
  // Define a threshold for mobile device screen width
  const mobileScreenWidthThreshold = 768; // Example threshold, adjust as needed
  
  // Check if the screen width is less than the threshold
  return screenWidth < mobileScreenWidthThreshold;
}

const styles = {
  p:{
    fontSize: '1vw',
  },
  h1:{
    fontSize: '3.1vw',
  },
  container: {
    display: 'flex',
    flexDirection: 'column', // Adjust to column layout on smaller screens
    alignItems: 'center', // Center content horizontally
    marginTop: '100px',
  },
  background: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    // backgroundImage: 'url("https://cdn.dribbble.com/users/2272349/screenshots/7207200/media/0bbd875631ca377750538eafebcf3f7e.gif")', // Replace with your actual path
    backgroundSize: 'cover',
    backgroundColor: '#000',
    // filter: 'blur(5px)',
    // zIndex: -1,
  },
  card: {
    // maxWidth: '100%',
    margin: '0 auto',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    // overflow: 'hidden',
    backgroundColor: '#fff',
    position: 'fixed',
    zIndex: 1000,
    width: '40vw', // Adjust width based on screen size
  },
  cardContent: {
    padding: '20px',
  },
  heading: {
    fontSize: '24px',
    color: '#333',
    marginBottom: '20px',
    textAlign: 'center',
  },
  inputContainer: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '8px',
    textAlign: 'left',
    fontSize: '16px',
    color: '#555',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    width: '100%',
    boxSizing: 'border-box',
    border: '1px solid #ccc',
    borderRadius: '5px',
    outline: 'none',
  },
  select: {
    padding: '10px',
    fontSize: '16px',
    width: '80%',
    boxSizing: 'border-box',
    border: '1px solid #ccc',
    borderRadius: '5px',
    outline: 'none',
    marginLeft: '10%',
    marginRight: '10%',
    marginBottom: '5%',
  },
  selectFormat: {
    padding: '5px',
    fontSize: '16px',
    width: '100%',
    boxSizing: 'border-box',
    border: '1px solid #ccc',
    borderRadius: '5px',
    outline: 'none',
    marginBottom: '5%',
  },
  checkboxContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '15px 0',
  },
  checkboxContainer: {
    padding: '0 20px  0 0',
  },
  checkboxLabel: {
    margin: '0 15px',
    textAlign: 'left',
    fontSize: '15px',
    color: '#333',
  },
  button: {
    padding: '15px',
    fontSize: '16px',
    backgroundColor: '#333',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    outline: 'none',
    width: '100%',
    transition: 'background-color 0.3s', // Add transition for smooth effect
  },
  // Hover effect
  buttonHover: {
    backgroundColor: '#444', // Slightly lighter shade for hover
  },
  // Click effect
  buttonActive: {
    backgroundColor: '#222', // Darker shade for click
  },
  buttonDisabled: {
    opacity: 0.5, 
    cursor: 'not-allowed', 
  },
  globalStyles: {
    body: {
      margin: 0,
      padding: 0,
    }, 
  },
  competitorsContainer: {
    maxHeight: '100%', // Set a maximum height for all competitors collectively
    // overflowY: 'auto',  // Add a scrollbar for overflow
  },

  competitorCard: {
    marginBottom: '10px',
  },

  competitorInfo: {
    fontSize: '14px',
    color: '#555',
    marginBottom: '6px',
  },
  navbar: {
    zIndex: 1000,
    display: 'flex',
    position:'fixed',
    width:'100%',
    top: 0,
    left: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    backgroundColor: 'white',
    color: 'white',
  },

  iconContainer: {
    marginRight: '20px',
  },

  icon: {
    fontSize: '24px',
  },

  loginRegisterContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
    width:'40vw',
  },

  button: {
    marginRight: '10px',
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#555',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    outline: 'none',
  },
  fullcard: {
    // maxWidth: '100%',
    margin: '0 auto',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    // overflow: 'hidden',
    backgroundColor: '#fff',
    position: 'relative',
    zIndex: 1,
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
  },

  tableHeader: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
    backgroundColor: '#f2f2f2',
  },

  tableRow: {
    border: '1px solid #ddd',
  },

  tableCell: {
    border: '1px solid #ddd',
    padding: '8px',
    cursor: 'pointer',
  },

  clickableCell: {
    border: '1px solid #ddd',
    padding: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },

  anchor: {
    textDecoration: 'none',
    color: '#000',
    transition: 'color 0.3s',
  },

  anchorHover: {
    textDecoration: 'underline',
    color: '#3366cc',
  },
  stepProgress: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '300px', // Adjust as needed
    margin: '0 auto', // Center horizontally
  },
  step: {
    flex: '1',
    textAlign: 'center',
    position: 'relative',
  },
  stepLine: {
    flex: '1',
    border: '1px solid #ccc', // Define the style of the line
    position: 'absolute',
    top: '50%',
    left: '0',
    transform: 'translateY(-50%)',
    zIndex: '-1', // Send the line behind the circles
  },
  stepCircle: {
    display: 'block',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: '#ccc',
    lineHeight: '20px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '1',
  },
  stepText: {
    marginTop: '30px', // Adjust as needed to position text below the circle
  },
  activeStep: {
    backgroundColor: 'green', // Change to desired active color
    color: '#fff', // Change to desired active color
  },
  errorStep: {
    color: 'red', // Change text color to red for the last step if error is true
  },
  errorCircle: {
    backgroundColor: 'red', // Change background color of circle to red when error is true
  },
  progressBar: {
    appearance: 'none',
    height: '100px',
    borderRadius: '100px',
  },
  dec3:{
    visibility: 'visible',
  },
  logo:{
    // position: 'fixed',
    // top: '10%',
    // right: '5%',
    opacity: '0.5',
    width: '200px',
    height: 'auto',
  },
  gif:{
    position:'fixed', 
    left:'-10%', 
    top:'-15%', 
    height: '130%', 
  
    zIndex: 10
  }
  


};

// Check if it's a mobile device
const isMobile = isMobileDevice();

// If it's a mobile device, adjust styles accordingly
if (isMobile) {
  styles.p.fontSize = '2.1vh';
  styles.h1.fontSize = '5vh';
  styles.card.width = '90%';
  styles.loginRegisterContainer.width = '90%';
  styles.dec3.visibility = 'hidden';
  styles.logo.opacity = '0.0';
  
}

if (!isMobile) {
  styles.gif.width = '120%';
  
}

export default styles;
