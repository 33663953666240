import React, { useEffect } from 'react';
import axios from 'axios';
import { CLOUDFRONT_URL, COGNITO_DOMAIN_NAME, APP_CLIENT_ID, APP_SECRET } from './constants';
import styles from './styles';

export function handleSignInClick() {
  const ENCODED_BASE_REDIRECT_URI = encodeURI(CLOUDFRONT_URL);
  const signInUrl = `https://${COGNITO_DOMAIN_NAME}.auth.us-west-2.amazoncognito.com/login?client_id=${APP_CLIENT_ID}&response_type=code&scope=email+openid&redirect_uri=${ENCODED_BASE_REDIRECT_URI}`;
  window.location.href = signInUrl;
}

export function handleRegisterClick() {
  const ENCODED_BASE_REDIRECT_URI = encodeURI(CLOUDFRONT_URL);
  const REGISTER_URL = `https://${COGNITO_DOMAIN_NAME}.auth.us-west-2.amazoncognito.com/signup?client_id=${APP_CLIENT_ID}&response_type=code&scope=email+openid&redirect_uri=${ENCODED_BASE_REDIRECT_URI}`;
  window.location.href = REGISTER_URL;
}

export function handleLogoutClick() {
  const ENCODED_BASE_REDIRECT_URI = encodeURI(CLOUDFRONT_URL);
  const CONGITO_LOGOUT_URL = `https://${COGNITO_DOMAIN_NAME}.auth.us-west-2.amazoncognito.com/logout?client_id=${APP_CLIENT_ID}&logout_uri=${ENCODED_BASE_REDIRECT_URI}`;
  window.location.href = CONGITO_LOGOUT_URL;
  localStorage.removeItem('id_token');
  localStorage.removeItem('access_token');
  localStorage.removeItem('sub');
}

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

function Navbar() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    console.log('Code:', code);
    localStorage.setItem('code', code);

    if (code) {
      const ENCODED_BASE_REDIRECT_URI = encodeURI(CLOUDFRONT_URL);
      const credentials = `${APP_CLIENT_ID}:${APP_SECRET}`;
      const encodedCredentials = btoa(credentials);
      const requestBody = `grant_type=authorization_code&client_id=${APP_CLIENT_ID}&code=${code}&redirect_uri=${ENCODED_BASE_REDIRECT_URI}`;
      const requestConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic ' + encodedCredentials,
        },
      };

      axios.post(
        `https://${COGNITO_DOMAIN_NAME}.auth.us-west-2.amazoncognito.com/oauth2/token`,
        requestBody,
        requestConfig
      )
      .then(async (response) => {
        const { id_token, access_token } = response.data;
        await localStorage.setItem('id_token', id_token);
        await localStorage.setItem('access_token', access_token);
        
        const decodedToken = parseJwt(id_token);
        const sub = decodedToken.sub;
        localStorage.setItem('sub', sub);
        
        // window.location.href = '/secure-route'; // Change this to your secure route
      })
      .catch(error => {
        console.error('Error exchanging code for tokens:', error);
      });
    }
  }, []);

  return (
    <nav style={styles.navbar}>
      <div style={styles.iconContainer}>
        <img
          src="https://www.drupal.org/files/ms1gsyel7pfokru79x9d.png"
          alt="User Icon"
          style={{ ...styles.icon, width: '120px' }}
        />
      </div>
      {/* <div style={styles.loginRegisterContainer}>
        {window.location.href === CLOUDFRONT_URL ? (
          <>
            <button style={styles.button} onClick={handleSignInClick}>Login</button>
            <button style={styles.button} onClick={handleRegisterClick}>Register</button>
          </>
        ) : (
          <button style={styles.button} onClick={handleLogoutClick}>Logout</button>
        )}
      </div> */}
    </nav>
  );
}

export default Navbar;
