import React, { useState,useEffect } from 'react';
import { BrowserRouter as Router,  Routes, Route, useNavigate } from 'react-router-dom';
import { CLOUDFRONT_URL, API_generateReport, API_getprogress, API_gettable} from './components/constants';
import Navbar, { handleSignInClick, handleRegisterClick, handleLogoutClick } from './components/navbar';
import ProgressBar from 'react-bootstrap/ProgressBar';

import styles from './components/styles';
import Fluxus from './image/Fluxus.gif';
import Fluxuspng from './image/Fluxus.png';
import dec1 from './image/DEC1.png';
import dec2 from './image/dec2.png';
import dec3 from './image/dec3v2.gif';
import vid1 from './video/Fates.mp4';

function SectionCard({ title, content, link }) {
  const navigate = useNavigate();

  const Backtogen = async () => {
    navigate(-1);
  };

  const doccall = async () => {
    window.open(link, '_blank'); // Open the link in a new tab
  };

  if (window.location.href == CLOUDFRONT_URL + 'result') {
    return (
      <div style={{ maxWidth: '90%' }}>
        <div style={{ ...styles.fullcard, margin: '0.5%', position: 'relative' }}>
          {/* <button style={{ ...styles.button, margin: '10px' }} onClick={Backtogen}>
            Back to Previous page
          </button>
          <button style={{ ...styles.button, margin: '10px', position: 'absolute', top: '0', right: '10px', backgroundColor: 'blue' }} onClick={doccall}>
            Google DOC
          </button> */}
          <div style={styles.cardContent}>
            <div style={styles.competitorsContainer}>
              <h1>{title}</h1>
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ maxWidth: '100%', height: '70vh', overflowY: 'auto' }}>
        <div style={{ ...styles.fullcard, margin: '0.5%' }}>
          {/* <button style={{ ...styles.button, margin: '10px' }} onClick={Backtogen}>
            Back to Previous page
          </button> */}
          <div style={styles.cardContent}>
            <div style={styles.competitorsContainer}>
              <h1>{title}</h1>
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function CheckProgressResult({ result }) {
   localStorage.removeItem('orilink');
   sessionStorage.removeItem('reportGenerated');
    const format = result.reportFormat
    const renderHTML = () => {
      console.log(format);
      return (
        <div>
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            {result.report}
          </pre>
        </div>
      );
    };
    const renderREAD = () => {
      console.log(format);
       return (
      
        <div>
          
          <div style={{zIndex:2}}>
          <button style={{ ...styles.button, margin: '10px', zIndex:99 }} onClick={() => {
              window.location.reload(); // Reload the previous page
          }}>
            Back to Previous page
          </button>
        
          <img src="https://www.drupal.org/files/ms1gsyel7pfokru79x9d.png" style={{ ...styles.logo,position:'absolute',right:'10px'}} />
          <br/>
          {result.report.introduction ? (
              <div>
                <h1>Introduction</h1>
                {result.report.introduction.name? (<p><span style={{ fontWeight: 'bold' }}>Name:</span> {result.report.introduction.name}</p>) : (<p></p>)}
                {result.report.introduction.type? (<p><span style={{ fontWeight: 'bold' }}>Type:</span> {result.report.introduction.type}</p>) : (<p></p>)}
                {result.report.introduction.sector? (<p><span style={{ fontWeight: 'bold' }}>Sector:</span> {result.report.introduction.sector}</p>) : (<p></p>)}
                {result.report.introduction.business_overview? (<p><span style={{ fontWeight: 'bold' }}>Business overview:</span> {result.report.introduction.business_overview}</p>) : (<p></p>)}
                {/* Include additional information as needed */}
              </div>
          ) : (
            <p></p>
          )}
          {result.report.business_health ? (
              <div>
                <h1>Business Health</h1>
                {result.report.business_health.headline_financial_overtime? (<p><span style={{ fontWeight: 'bold' }}>Headline:</span> {result.report.business_health.headline_financial_overtime}</p>) : (<p></p>)}
                {result.report.business_health.company_structure ? (<p><span style={{ fontWeight: 'bold' }}>Company Structure:</span> {result.report.business_health.company_structure}</p>) : (<p></p>)}
                {/* <p><span style={{ fontWeight: 'bold' }}>Annual Report:</span> {result.report.business_health.last_annual_report}</p> */}
                {result.report.business_health.service_roadmaps ? (<p><span style={{ fontWeight: 'bold' }}>Roadmap:</span> {result.report.business_health.service_roadmaps}</p>) : (<p></p>)}
                {/* Include additional information as needed */}
              </div>
          ) : (
            <p></p>
          )}
          {result.report.target_audience ? (
              <div>
                <h1>Target Audience</h1>
                {result.report.target_audience.demographic ? (
                  <p>
                    <span style={{ fontWeight: 'bold' }}>Demographic:</span>{' '}
                    {Array.isArray(result.report.target_audience.demographic) ? (
                      result.report.target_audience.demographic.map((item, index) => (
                        <span key={index}>
                          {typeof item === 'object' ? `${item.age}, ${item.income}` : item}
                          {index !== result.report.target_audience.demographic.length - 1 ? ', ' : ''}
                        </span>
                      ))
                    ) : (
                      result.report.target_audience.demographic
                    )}
                  </p>
                ) : (
                  <p></p>
                )}

                {result.report.target_audience.geographic ? (
                  <p>
                    <span style={{ fontWeight: 'bold' }}>Geographic:</span>{' '}
                    {Array.isArray(result.report.target_audience.geographic) ? (
                      result.report.target_audience.geographic.map((item, index) => (
                        <span key={index}>
                          {typeof item === 'object' ? item.region : item}
                          {index !== result.report.target_audience.geographic.length - 1 ? ', ' : ''}
                        </span>
                      ))
                    ) : (
                      result.report.target_audience.geographic
                    )}
                  </p>
                ) : (
                  <p></p>
                )}

                {result.report.target_audience.sector ? (
                  <p>
                    <span style={{ fontWeight: 'bold' }}>Sector:</span>{' '}
                    {Array.isArray(result.report.target_audience.sector) ? (
                      result.report.target_audience.sector.map((item, index) => (
                        <span key={index}>
                          {typeof item === 'object' ? item.industry : item}
                          {index !== result.report.target_audience.sector.length - 1 ? ', ' : ''}
                        </span>
                      ))
                    ) : (
                      result.report.target_audience.sector
                    )}
                  </p>
                ) : (
                  <p></p>
                )}
                {/* Include additional information as needed */}
              </div>
          ) : (
            <p></p>
          )}

          {result.report.competitors ? (
            <div>
              <h1>Competitors</h1>
              <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ borderBottom: '1px solid black', padding: '8px', fontWeight: 'bold' }}>Name</th>
                    <th style={{ borderBottom: '1px solid black', padding: '8px', fontWeight: 'bold' }}>Sector</th>
                    <th style={{ borderBottom: '1px solid black', padding: '8px', fontWeight: 'bold' }}>Proposition</th>
                    <th style={{ borderBottom: '1px solid black', padding: '8px', fontWeight: 'bold' }}>Services</th>
                  </tr>
                </thead>
                <tbody>
                  {result.report.competitors.map((competitor, index) => (
                    <tr key={index}>
                      <td style={{ borderBottom: '1px solid black', padding: '8px' }}>{competitor.name}</td>
                      <td style={{ borderBottom: '1px solid black', padding: '8px' }}>{competitor.sector}</td>
                      <td style={{ borderBottom: '1px solid black', padding: '8px' }}>{competitor.proposition}</td>
                      <td style={{ borderBottom: '1px solid black', padding: '8px' }}>{competitor.services}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p></p>
          )}
          </div>
          {/* <h3 style={{marginTop:'120px'}}>Want to find out more? Book a meeting to find out how we can help your company with Al</h3>
          <button style={{ ...styles.button, zIndex:99 ,backgroundColor: "blue",padding: '10px',right:'10px'}} onClick={() => {
              window.open('https://calendar.google.com/calendar/appointments/schedules/AcZssZ3YLuYlTAlcB0-B77sD47hhfdoJEb-tPqrMv_aTUc109-2nRadE-jPsSZVhPuww7q3l8Id4j1zQ?gv=true'); // Reload the previous page
          }}>
            Book an appointment
          </button> */}
          <script id='formless_embed' src='https://embed.formless.ai/embed.js' async='' data-type='trigger' data-trigger-type='banner' data-trigger-title="AI Solutions for You" data-trigger-subtitle="Let our AI/ML consultants transform challenges into opportunities for your business." data-conversation-id="xk8cDPjV8HDG"></script>
        </div>
          
          
      );
    };
  
    const renderJSON = () => {
      return (
        <div >
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(result.report, null, 2)}
          </pre>
        </div>
      );
    };
  
    const renderMarkdown = () => {
      // Convert result to Markdown format (customize based on your needs)
      // You can use a library like marked.js for markdown rendering
      // Example: import marked from 'marked'; and use marked(result)
      return (
        <div>
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            {result.report}
          </pre>
        </div>
      );
    };
  
    const renderContent = () => {
      switch (format) {
        case 'markdown':
          return renderMarkdown();
        case 'html':
          return renderHTML();
        case 'read':
          return renderREAD();
        default:
          return renderJSON();
      }
    };
  
    return (
      <SectionCard
        // title={result.report.introduction.name}
        content={renderContent()}
        link={result.doclink}
      />
    );
}

function StepProgressBar({ currentStep, error }) {
  const steps = ['Start', 'In Progress', error ? 'Error' : 'Done'];

  useEffect(() => {
    if (error) {
      // Do something when error occurs
      console.log('Error occurred:', error);
    }
  }, [error]);

  return (
    <div style={styles.stepProgress}>
      {steps.map((step, index) => (
        <div key={index} style={{ ...styles.step, ...(error && index === steps.length - 1 && styles.errorStep) }}>
          <span style={{ ...styles.stepCircle, ...(index <= currentStep && styles.activeStep), ...(error && styles.errorCircle) }}>
            {index + 1}
          </span>
          {index < steps.length - 1 && ( // Add line except for the last step
            <div style={styles.stepLine}></div>
          )}
          <div style={styles.stepText}>{step}</div>
        </div>
      ))}
    </div>
  );
}

function HistoryResultpage({ result = [], getprogressparam = () => {} }) {
  const [historyResult, setHistoryResult] = useState(null);

  useEffect(() => {
      try {
        const idToken = localStorage.getItem('id_token');
        if (!idToken) {

          handleSignInClick();
          return;
        }
        const fetchData = async () => {
          try {
            const response = await fetch(API_gettable, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`,
              },
            });
            if (response.ok) {
              const responseData = await response.json();
              setHistoryResult(responseData);
            } else {
              console.error('Error:', response.statusText);
            }
          } catch (error) {
            console.error('Error:', error.message);
          }
        };
        fetchData();
      } catch (error) {
        console.error('Error:', error.message);
    }
  }, [result]);

  const renderHTMLTable = () => {
    if (!historyResult) {
      return <div>Loading...</div>;
    }

    const transaction=historyResult.body;
    // Sort the result array by date in descending order
    const sortedResult = transaction.slice().sort((a, b) => b.updatedAt - a.updatedAt);

    return (
      <div>
        <table style={styles.table}>
          <thead>
            <tr style={styles.tableHeader}>
              <th>Transaction ID</th>
              <th>Input</th>
              <th>Format</th>
              <th>Document</th>
              <th>Updated At</th>
              <th>Transaction Status</th>
            </tr>
          </thead>
          <tbody>
            {sortedResult.map((transaction) => (
              <tr key={transaction.transactionID} style={styles.tableRow}>
                <td onClick={() => getprogressparam(transaction.transactionID)} style={styles.clickableCell}>
                  <a
                    style={styles.anchor}
                    href="#"
                    onMouseOver={(e) => e.target.style.color = '#3366cc'}
                    onMouseOut={(e) => e.target.style.color = '#000'}
                  >
                    {transaction.transactionID}
                  </a>
                </td>
                <td style={styles.tableCell}>{transaction.input}</td>
                <td style={styles.tableCell}>{transaction.format}</td>
                <td style={{ ...styles.tableCell, maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  <a href={transaction.link} title={transaction.link} style={{ textDecoration: 'none' }}>
                    {transaction.link}
                  </a>
                </td>
                <td style={styles.tableCell}>{new Date(transaction.updatedAt).toLocaleString()}</td>
                <td style={styles.tableCell}>{transaction.transactionStatus}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderContent = () => {
    return renderHTMLTable();
  };

  return (
    <SectionCard
      title="Transaction Results"
      content={renderContent()}
    />
  );
}

 


function App() {
  const navigate = useNavigate();
  const [webLink, setWebLink] = useState(localStorage.getItem('orilink')? localStorage.getItem('orilink') :'https://www.netflix.com' );
  const [selectedFormat, setSelectedFormat] = useState('read');
  const [gptModel, setGptModel] = useState('gpt-4-1106-preview');
  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState(false);
  const [showGif, setShowGif] = useState(true);
  const [hover, setHover] = useState(false);
  const [active, setActive] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [selectedSections, setSelectedSections] = useState({
    introduction: true,
    finance: true,
    audience: true,
    competitors: true,
  });
  const [percent, setpercent] = useState(0);
  const [result, setResult] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
    // Separate state for custom transaction result
  const [customTransactionResult, setCustomTransactionResult] = useState(null);
  const [HistoryResult, setHistoryResult] = useState(null);
  const [scriptAdded, setScriptAdded] = useState(true);
  
  const handleWebLinkChange = (e) => {
    setWebLink(e.target.value);
  };

  // if(localStorage.getItem('orilink')){
  //   setWebLink(localStorage.getItem('orilink'));
  // }



  const [gptModels, setGptModels] = useState({
    // introduction: 'gpt-4-1106-preview',
    // finance: 'gpt-4-1106-preview',
    // audience: 'gpt-4-1106-preview',
    // competitors: 'gpt-4-1106-preview',

    // anthropic.claude-instant-v1
    // introduction: 'anthropic.claude-instant-v1',
    // finance: 'anthropic.claude-instant-v1',
    // audience: 'anthropic.claude-instant-v1',
    // competitors: 'anthropic.claude-instant-v1',

    // anthropic.claude-3-sonnet-20240229-v1:0
    // introduction: 'anthropic.claude-3-sonnet-20240229-v1:0',
    // finance: 'anthropic.claude-3-sonnet-20240229-v1:0',
    // audience: 'anthropic.claude-3-sonnet-20240229-v1:0',
    // competitors: 'anthropic.claude-3-sonnet-20240229-v1:0',

    // anthropic.claude-3-haiku-20240307-v1:0
    introduction: 'anthropic.claude-3-haiku-20240307-v1:0',
    finance: 'anthropic.claude-3-haiku-20240307-v1:0',
    audience: 'anthropic.claude-3-haiku-20240307-v1:0',
    competitors: 'anthropic.claude-3-haiku-20240307-v1:0',
    
  });
  
  const handleGptModelChange = (section, selectedModel) => {
    setGptModels((prevGptModels) => ({
      ...prevGptModels,
      [section]: selectedModel,
    }));
  };
  const handleSectionChange = (section) => {
    setSelectedSections((prevSelectedSections) => ({
      ...prevSelectedSections,
      [section]: !prevSelectedSections[section],
    }));
  };

  const handleFormatChange = (e) => {
    setSelectedFormat(e.target.value);
  };
  
  const generateReport = async () => {
    await setDisabled(true);
    setTimeout(() => {
        setDisabled(false);
    }, 15000);
    console.log('Generating Report...');
    console.log('Web Link:', webLink);
    console.log('GPT Model:', gptModel);
    console.log('Selected Sections:', selectedSections);
    let formatale = selectedFormat;

    
        try {
            const idToken = localStorage.getItem('id_token');
            const original = localStorage.getItem('orilink');
            const isValidUrl = (url) => {
                const urlPattern = /^(https):\/\/[^ "]+$/;
                return urlPattern.test(url);
            };

            if (!webLink.trim()) {
                alert('Please enter the company URL.');
                return;
            } else {
                let formattedUrl = webLink.trim();

                // If the URL doesn't start with http:// or https://, prepend it with https://
                if (!formattedUrl.startsWith('https://')) {
                    formattedUrl = "https://" + formattedUrl;
                    await setWebLink(formattedUrl)
                }

                if (!isValidUrl(formattedUrl)) {
                    alert('Please enter a valid URL.');
                    return;
                }

                const Orilink = async () => {
                    if (!original) {
                        localStorage.setItem('orilink', formattedUrl);
                        console.log(localStorage.getItem('orilink'));
                        return formattedUrl.toString();
                    } else {
                        return localStorage.getItem('orilink').toString();
                    }
                }

                // Call Orilink and await its result
                const url = await Orilink();
                console.log('url : ' + url);

                if (!idToken || window.location.href == CLOUDFRONT_URL) {
          
                    handleSignInClick();
                } else {
                    localStorage.removeItem('orilink');
                    // sessionStorage.removeItem('reportGenerated');
                    const requestBody = {
                        model: gptModel,
                        url: url, // Use the retrieved URL here
                        sections: Object.keys(selectedSections).filter((section) => selectedSections[section]),
                        modelsection: gptModels,
                        format: formatale,
                        sub: localStorage.getItem('sub'),
                    };

                    const response = await fetch(API_generateReport, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${idToken}`,
                        },
                        body: JSON.stringify(requestBody),
                    });

                    const responseData = await response.json();
                    await setResult(responseData);
                    if (responseData.statusCode == 429) {
                      // createGoogleDoc();
                      setError(true);
                      alert("Oops! It looks like you've reached your usage limit for now. Please try again later or contact support if you need assistance.");
                      // navigate('/result');
                    }
                    setIsHovered(true);
                    setCurrentStep(1);
                
                }
            }
        } catch (error) {
            console.error('Error:', error.message);
            alert('Session timed out. Please log in again before generating the report.');
        }
   
};


  const getprogress = async () => {
    if (window.location.href != CLOUDFRONT_URL){
      // generatePDF();
      try {
            const idToken = localStorage.getItem('id_token');
            if (!idToken) {
      
                return;
            }
          const response = await fetch(API_getprogress + customTransactionID, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${idToken}`,
            },
          });
      
          if (response.ok) {
            const responseData = await response.json();

            setCustomTransactionResult(responseData); // Set custom transaction result
            setIsHovered(true);
            setpercent(responseData.body.percent);
            if (responseData.body.message === 'The company report has successfully been generated.') {
              // createGoogleDoc();
              setCurrentStep(2);
              // navigate('/result');
            }
            if (responseData.body.message === "The company report failed to generate. Please send another HTTP POST request to the ''/generate-company-report'' endpoint. If this error persists, please contact ''info@fluxus.io''.") {
              // createGoogleDoc();
              setError(true);
              setCurrentStep(2);
              // navigate('/result');
            }
            if (responseData.statusCode == 500 || responseData.statusCode==400  || responseData.statusCode==404) {
              // createGoogleDoc();
              setError(true);
              setCurrentStep(2);
              // navigate('/result');
            }
          } else {
            console.error('Error:', response.statusText);
            setError(true);
            setCurrentStep(2);
          }
        
      } catch (error) {
        console.error('Error:', error.message);
      }}else{
        // alert('please sign in before generate the report');
        handleSignInClick();
      }
  };

  const getprogressparam = async (transactionIDparam) => {
    if (window.location.href != CLOUDFRONT_URL){
      // generatePDF();
      try {
        const idToken = localStorage.getItem('id_token');
        if (!idToken) {
  
            handleSignInClick();
            return;
        }
          const response = await fetch(API_getprogress + transactionIDparam , {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${idToken}`,
            },
          });
      
          if (response.ok) {
            const responseData = await response.json();

            setCustomTransactionResult(responseData); // Set custom transaction result
            setIsHovered(true);
            setpercent(responseData.body.percent);

            if (responseData.body.message === 'The company report has successfully been generated.') {
              // createGoogleDoc();
              setCurrentStep(2);
              // navigate('/result');
            }
            //"The company report failed to generate. Please send another HTTP POST request to the ''/generate-company-report'' endpoint. If this error persists, please contact ''info@fluxus.io''."
            if (responseData.body.message === "The company report failed to generate. Please send another HTTP POST request to the ''/generate-company-report'' endpoint. If this error persists, please contact ''info@fluxus.io''.") {
              // createGoogleDoc();
              setError(true);
              setCurrentStep(2);
              // navigate('/result');
            }
            if (responseData.statusCode == 429) {
              // createGoogleDoc();
              setError(true);
              alert("Oops! It looks like you've reached your usage limit for now. Please try again later or contact support if you need assistance.");
              // navigate('/result');
            }
            if (responseData.statusCode == 500 || responseData.statusCode==400  || responseData.statusCode==404) {
              // createGoogleDoc();
              setError(true);
              setCurrentStep(2);
              // navigate('/result');
            }
          } else {
            console.error('Error:', response.statusText);
            setError(true);
            setCurrentStep(2);
          }
        
      } catch (error) {
        console.error('Error:', error.message);
      }}else{
       // alert('please sign in before generate the report');
        handleSignInClick();
      }
  };

    const [customTransactionID, setCustomTransactionID] = useState('');

    useEffect(() => {
      let intervalId; // Variable to store the interval ID
      const checkProgress = async () => {
        if (customTransactionID && window.location.href !== CLOUDFRONT_URL) {
          try {
            const idToken = localStorage.getItem('id_token');
            if (!idToken) {
      
                handleSignInClick();
                return;
            }
            const response = await fetch(API_getprogress+customTransactionID, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`,
              },
            });

            if (response.ok) {
              const responseData = await response.json();
              console.log(responseData);
              setCustomTransactionResult(responseData); // Set custom transaction result
              setpercent(responseData.body.percent);
              console.log(responseData.body.percent);

              if (responseData.body.message === 'The company report has successfully been generated.') {
                // Stop the interval when the condition is met
                setCurrentStep(2);
                setScriptAdded(true);
                // navigate('/result');
                clearInterval(intervalId);
              }
              if (responseData.body.message === "The company report failed to generate. Please send another HTTP POST request to the ''/generate-company-report'' endpoint. If this error persists, please contact ''info@fluxus.io''.") {
                // createGoogleDoc();
                setError(true);
                setCurrentStep(2);
                // navigate('/result');
              }
              if (responseData.statusCode == 500 || responseData.statusCode==400  || responseData.statusCode==404) {
                // createGoogleDoc();
                setError(true);
                setCurrentStep(2);
                // navigate('/result');
              }
            } else {
              console.error('Error:', response.statusText);
              setError(true);
              setCurrentStep(2);
            }
          } catch (error) {
            console.error('Error:', error.message);
          }
        }
       
      };

      // Set interval to call checkProgress every 30 seconds
      intervalId = setInterval(() => {
        checkProgress();
      }, 5000);

      const timer = setTimeout(() => {
        setShowGif(false);
      }, 4000);

      // Clear the interval when the component is unmounted or when the condition is met
      return () => {
        clearInterval(intervalId);
        clearTimeout(timer);
      };
    }, [customTransactionID]);

    useEffect(() => {

        // Update customTransactionID when result.body.transactionID changes
        if (result && result.body && result.body.transactionID) {
          setCustomTransactionID(result.body.transactionID);
          // Call getprogress after setting customTransactionID
          setTimeout(() => {
            getprogressparam(result.body.transactionID);
          }, 1000);

        }

    }, [result]);

    const gethistory = async () => {
      if (window.location.href !== CLOUDFRONT_URL) {
        try {
            const idToken = localStorage.getItem('id_token');
            if (!idToken) {
      
                handleSignInClick();
                return;
            }
            const response = await fetch(API_gettable, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`,
              },
            });
        
            if (response.ok) {
              const responseData = await response.json();
  
              setHistoryResult(responseData); // Set custom transaction result
              // navigate('/history');
            } else {
              console.error('Error:', response.statusText);
            }
          
        } catch (error) {
          console.error('Error:', error.message);
        }}else{
          alert('please sign in before checking the history');
        }
    };



  const handleCustomTransactionIDChange = (e) => {
    setCustomTransactionID(e.target.value);
  };

  const displayTransactionCard = () => {
    if (result && result.body.transactionID) {
      return (
        <div style={styles.resultCard}>
          <h2>Report Process</h2>
          <p>{result.body.message}</p>
          <p>Transaction ID: {result.body.transactionID}</p>
        </div>
      );
    } else {
      return null;
    }
  };

 
  console.log("localStorage.getItem('orilink')=" + localStorage.getItem('orilink'));

  if (window.location.href !== CLOUDFRONT_URL) {
      if (localStorage.getItem('orilink') && localStorage.getItem('orilink') !== "") {
        setTimeout(function() {
          setDisabled(true);
        }, 500); 
          if (!sessionStorage.getItem('reportGenerated')) {
              sessionStorage.setItem('reportGenerated', 'true');
              setTimeout(function() {
                  generateReport();
              }, 3000); 
          }
      }
  }

  if (window.location.href !== CLOUDFRONT_URL) {
      setTimeout(function() {
        if(!localStorage.getItem('id_token')){
          const defaultpage = `https://www.panya.fyi/`;
          window.location.href = defaultpage;
        }else{
          const token = localStorage.getItem('id_token');
           // Extract the expiration time from the token payload
          const payload = JSON.parse(atob(token.split('.')[1])); // Decodes the base64 encoded payload
          const expirationTime = payload.exp * 1000; // Convert expiration time to milliseconds

          // Check if the token is expired
          const isExpired = Date.now() >= expirationTime;

          const expirationDateTime = new Date(expirationTime).toLocaleString();
          console.log('Token expiration date and time:', expirationDateTime);

          if (isExpired) {
              // Token is expired, handle redirection or other actions
              const defaultPage = 'https://www.panya.fyi/';
              window.location.href = defaultPage;
          } else {
              // Token is still valid, proceed with other actions
              console.log('Token is still valid.');
          }
        }
      }, 3000); 
  }

  if (window.location.href == CLOUDFRONT_URL) {
    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token');
    sessionStorage.removeItem('reportGenerated');
  }



  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "https://embed.formless.ai/embed.js";
  //   script.async = true;
  //   script.setAttribute('data-type', 'trigger');
  //   script.setAttribute('data-trigger-bg-color', '#000000');
  //   script.setAttribute('data-trigger-icon-color', '#FFFFFF');
  //   script.setAttribute('data-trigger-icon-type', 'chat');
  //   script.setAttribute('data-conversation-id', 'xk8cDPjV8HDG');
    
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  useEffect(() => {
    // Create the script element
    const script = document.createElement('script');

    // Set the script attributes
    script.id = 'formless_embed';
    script.src = 'https://embed.formless.ai/embed.js';
    script.async = true;
    script.dataset.type = 'trigger';
    script.dataset.triggerType = 'banner';
    script.dataset.triggerTitle = 'AI Solutions for You';
    script.dataset.triggerSubtitle = 'Let our AI/ML team transform challenges into opportunities for your business.';
    script.dataset.conversationId = 'xk8cDPjV8HDG';

    // Append the script to the document body
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
      <div style={styles.container}>
        < Routes>
        <Route path="/" element={<React.Fragment>
      
            <div style={styles.background}></div>
            <Navbar />

            <div style={{ ...styles.card,background: 'rgba(255,255,255,0)',  position: 'fixed',top: '35%',left: '50%',transform: 'translate(-50%, -50%)',  }}>
                <h1 style={{...styles.h1, textAlign: 'center', color:'white',marginBottom:'1h' }}>Company Reports Powered by AI - Free Beta </h1>
                <p style={{ ...styles.p,textAlign: 'center', color:'white', }}>Unlock the power of information with just a URL! Simply input the website of any company and watch our AI-driven system uncover valuable insights.</p>
            </div>
            <div style={{ ...styles.card,background: 'linear-gradient(180deg, rgba(0,0,0,0.35) 35%, rgba(255,255,255,0.45) 100%)',  position: 'fixed',top: '65%',left: '50%',transform: 'translate(-50%, -50%)', }}>
              <div style={styles.cardContent}>
                {/* <h1 style={styles.heading}>Generate Report</h1> */}

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ ...styles.inputContainer, flex: '1' }}>
                    <label style={{...styles.label,color:'white'}}>
                      Web Link:
                      <input
                          type="text"
                          value={webLink}
                          onChange={handleWebLinkChange}
                          onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                  event.preventDefault();
                                  generateReport(event);
                              }
                          }}
                          onClick={() => setWebLink('')} // This line is added
                          style={styles.input}
                          placeholder="Enter the company URL here..."
                      />
                    </label>
                  </div>
                  <button
                    style={{
                      ...styles.button,
                      ...(hover && styles.buttonHover),
                      ...(active && styles.buttonActive),
                      ...(disabled && styles.buttonDisabled),
                      background: 'radial-gradient(circle, rgba(168,127,51,1) 0%, rgba(62,50,10,1) 100%)'
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    onMouseDown={() => setActive(true)}
                    onMouseUp={() => setActive(false)}
                    onClick={generateReport}
                    disabled={disabled}
                  >
                    Generate Report
                </button>
                </div>

                <p style={{ ...styles.p,textAlign: 'center', color:'white',marginBottom:'3vh' }}>Not sure where to start? Try entering the URL of your favorite company and discover what makes them tick!</p>

                {/* <script src="https://embed.formless.ai/embed.js" async="" data-type="trigger" data-trigger-bg-color="#000000" data-trigger-icon-color="#FFFFFF" data-trigger-icon-type="chat" data-conversation-id="xk8cDPjV8HDG"></script> */}

              
              </div>
            </div>
            <img
              src={dec1}
              alt="Loading GIF"
              style={{
              position: 'fixed',
              left: '-10vw',
              width: '80vh',
              top: '50vh',
              zIndex: 0,
              opacity: 0.8
               }}
            />

            <img
            src={dec2}
            alt="Loading GIF"
            style={{
            position: 'fixed',
            right: '-10vw',
            width: '60vh',
            top: '-20vh',
            zIndex: 0,
            opacity: 0.5
            }}
            />               
            
            
                {customTransactionResult && customTransactionResult.body && JSON.stringify(customTransactionResult.body.percent) >= 100  && (
                      <div style={{ ...styles.card, flex: '0 0 50%', top: '54%', position: 'fixed',left: '50%',transform: 'translate(-50%, -50%)', width:'90vw' , height:'90%' }}>
                        <div style={styles.cardContent}>
                          <CheckProgressResult result={customTransactionResult.body} />
                        </div>
                      </div>
                )}


                {customTransactionResult && customTransactionResult.body && JSON.stringify(customTransactionResult.body.percent) < 100 && (
                           <div style={{ ...styles.card, top: '54%',position: 'fixed',left: '50%',transform: 'translate(-50%, -50%)', width:'90vw',height:'90%' , justifyContent: 'center',alignItems: 'center'}}> 
                           <div style={{...styles.cardContent}}>
                             <div className="container" style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center',textAlign: 'center',justifyContent: 'center' }}>
                             {!error &&(<h2 style={{zIndex: 11}}>Report is generating</h2>)}
                             {error && <h2>Generate error</h2>}
                           
                             {/* {!error &&(<img src="https://i.pinimg.com/originals/ea/aa/9a/eaaa9a8500c189358a596096a5794da1.gif" alt="Loading GIF" style={{ width: '30vw' }} />)} */}
                             {!error &&(<img src="https://www.explainxkcd.com/wiki/images/4/41/time-animated.gif" alt="Loading GIF" style={{...styles.gif}} />)}
                             {/* {!error &&(<iframe style={{position:'fixed', left:'-10%', top:'-15%', height: '120%', width: '120%', zIndex: 10}} src="https://www.youtube.com/embed/RcbdwoxQMjE?si=sOTnbK-ljWMwCJRv"  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>)} */}
                             {/* <iframe src="https://playpager.com/animalquiz/index.html" width="560" height="384" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen margin="30px"></iframe> */}
                             {error &&(<img src="https://img.freepik.com/premium-vector/red-cross-mark-icon-negative-choice-symbol-sign-app-button_744955-339.jpg" alt="Loading GIF" style={{ width: '25vw' }} />)}
                          
                              {/* <StepProgressBar currentStep={currentStep} error={error} /> */}
                              {/* <ProgressBar animated variant="info" now={percent} label={`${percent}%`} /> */}
                              <progress 
                                className={styles.progressBar}
                                value={percent} 
                                max={100}
                                style={{width:'50%',zIndex: 11,backgroundColor: 'white',  borderradius: "10px"}}
                                >
                                  {percent}%
                              </progress>
                            
                             {/* {result && result.body && result.body.transactionID && (
                                <div style={styles.resultCard}>
                                  <p>Transaction ID: {result.body.transactionID}</p>
                                </div>
                              )} */}

                              {result && result.body && !result.body.transactionID && (
                                <div style={styles.resultCard}>

                                  <p>{result.body.message}</p>
                                  <p>Transaction ID not available</p>
                                </div>
                              )}

                              {customTransactionResult && (
                                <div style={{...styles.resultCard, zIndex: 11 }}>
                                  {/* <h2>Report process status</h2> */}
                                  <p>{customTransactionResult.body.message}</p>
                                 
                                </div>
                              )}

                              {error &&(
                               <button style={{...styles.button,background:'radial-gradient(circle, rgba(134,134,134,1) 0%, rgba(56,56,56,1) 100%)'}}onClick={() => {
                                window.location.reload(); // Reload the previous page
                                }}>Try Again</button>
                               )}
                              </div>
                           </div>
                         </div>

                  )}

                     
                
                      <div class="container">
                         <div>
                            {showGif && (
                              <img
                                src={dec3}
                                alt="Loading GIF"
                                style={{...styles.dec3,
                                  position: 'fixed',
                                  top: '52%',left: '50%',transform: 'translate(-50%, -50%)', width:'42vw',
                                  zIndex: 2,opacity: 0.5
                                }}
                              />
                            )}
                           
                              <img
                                src={dec3}
                                alt="Loading GIF"
                                style={{
                                  ...styles.dec3,
                                  position: 'fixed',
                                  top: '52%',left: '50%',transform: 'translate(-50%, -50%)', width:'42vw',
                                  zIndex: 1,opacity: 0.5
                                }}
                              />

                            
                          </div>

                          {/* <div style={{ position: 'fixed', top: '22%', left: '73vw', width:'25%', Height:'100%',zIndex:2 }}>
                            <div style={styles.cardContent}>
                              <p style={{ color:'white' , fontSize:'1.3vw'}}> &nbsp;&nbsp;&nbsp; Embrace the future of AI-driven innovation and move your business forward</p>
                            </div>
                          </div>

                          <div style={{ position: 'fixed', top: '6%', right: '5vw', width:'30%', Height:'100%',zIndex:2,textAlign:'end' }}>
                            <div style={styles.cardContent}>
                              <p style={{ color:'white' , fontSize:'2.8vw', fontWeight: 'bold'}}> AI Custom Models</p>
                            </div>
                          </div>


                          <div style={{ position: 'fixed', top: '40%', left: '73vw', width:'25%', Height:'100%',zIndex:2, textAlign:'start' }}>
                            <div style={styles.cardContent}>
                              <p style={{ color:'white' , fontSize:'0.8 vw'}}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   Go from generic language models to private knowledge models based on your data. We can do this for you with our customized AI solutions. Get in touch for a personalized consultation and discover how our bespoke services can revolutionize your business operations.</p>
                            </div>
                          </div>

                          <div style={{ position: 'fixed', top: '70vh', right: '1vw', width:'25%',zIndex:2, textAlign:'end' }}>
                            <div style={styles.cardContent}>
              
                              <div class="contact-info" style={{ color:'white'}}>
                                  <p style={{ fontSize:'1.3vw'}}>Let’s get in touch</p>
                                  <p style={{ fontSize:'0.9vw', color:'white'}}>Email: <a style={{ color:'white'}} href="mailto:info@fluxus.io">info@fluxus.io</a></p>
                                  <p style={{ fontSize:'0.9vw', color:'white'}}>Phone: <a style={{ color:'white'}} href="tel:+441392581040">+44 1392 58 1040</a></p>
                                  <p style={{ fontSize:'0.9vw', color:'white'}}>Follow us on: <a style={{ color:'white'}} href="https://www.linkedin.com/fluxus">LinkedIn</a>, <a style={{ color:'white'}} href="https://twitter.com/fluxus">Twitter</a></p>
                              </div>
                            </div>
                          </div> */}
                      </div>
                      
                  
                      <div style={{...styles.loginRegisterContainer, position: 'fixed',top: '86%',left: '50%',transform: 'translate(-50%, -50%)',zIndex:2}}>
                        {window.location.href === CLOUDFRONT_URL ? (
                          <>
                            <button style={{...styles.button,position: 'fixed',left:'1%',background:'radial-gradient(circle, rgba(134,134,134,1) 0%, rgba(56,56,56,1) 100%)'}} onClick={handleSignInClick}>Sign in</button>
                            <p style={{ textAlign: 'center', justifyContent:'center',color:'grey',fontSize:'0.8vw',position: 'fixed',left:'50%',transform: 'translate(-50%, -50%)' }}>Please sign in to generate a report.</p>
                            <button style={{...styles.button,position: 'fixed',right:'1%',background:'radial-gradient(circle, rgba(134,134,134,1) 0%, rgba(56,56,56,1) 100%)'}} onClick={handleRegisterClick}>Register</button>
                          </>
                        ) : (
                          <button style={{...styles.button,position: 'fixed',right:'1%',background:'radial-gradient(circle, rgba(134,134,134,1) 0%, rgba(56,56,56,1) 100%)', marginTop:'20px'}} onClick={handleLogoutClick}>Sign out</button>
                        )}
                      </div>
             
           
          
            
            {/* {displayTransactionCard()} */}

            {/* New card for custom transaction ID */}
            {/* <div style={{ ...styles.card, flex: '0 0 50%', marginLeft: '10px'  }}>
              <div style={styles.cardContent}>
                <h1 style={styles.heading}>Transaction check</h1>
                <div style={styles.inputContainer}>
                  <label style={styles.label}>
                    Transaction ID:
                    <input
                      type="text"
                      value={customTransactionID}
                      onChange={handleCustomTransactionIDChange}
                      style={styles.input}
                    />
                  </label>
                </div>
                <button style={styles.button} onClick={getprogress}>
                  Check result
                </button>
                <button style={styles.button} onClick={gethistory}>
                  History
                </button>
                {customTransactionResult && (
                  <div style={styles.resultCard}>
                    <h2>Report process status</h2>
                    <p>{customTransactionResult.body.message}</p>
                  </div>
                )}
              </div>
            </div> */}
            {/* Conditionally render the CheckProgressResult component */}
            
           </React.Fragment>} />
          <Route path="/result" element={<React.Fragment>
            <div style={styles.background}></div>
            <Navbar />
            
            {customTransactionResult && customTransactionResult.body && (
                  <CheckProgressResult result={customTransactionResult.body} />
              )}
            </React.Fragment>}/>
          <Route path="/history" element={<React.Fragment>
            <div style={styles.background}></div>
            <Navbar />
            
            {HistoryResult && HistoryResult.body && (
                  <HistoryResultpage result={HistoryResult.body} getprogressparam={getprogressparam}/>
              )}
            </React.Fragment>}/>
        </ Routes>
      </div>

  );
}

export default App;